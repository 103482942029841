import { useDataBlockService } from "~/src/services/DataBlockService";
import {
  abortNavigation,
  createError,
  defineNuxtRouteMiddleware,
} from "#imports";

export default defineNuxtRouteMiddleware(async (to) => {
  const { useGetDataBlockQuery } = useDataBlockService();
  const result = await useGetDataBlockQuery(String(to.params.id));

  if (to.params.id === "new") return true;
  else if (!result.data.value)
    return abortNavigation(
      createError({
        statusCode: 404,
        message: "Data Block not found",
      })
    );
});
